import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Avatar, Box, Divider, Typography } from "@mui/material";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px", // Rounded corners
    "& fieldset": {
      border: "0px dashed #D3D3D3", // Dashed border
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff", // Focused border color
    },
  },
  "& .MuiInputBase-input": {
    color: "#33333380",
    fontSize: 14,
    fontFamily: "montserrat",
  },
});

const InputHome = () => {
  return (
    <div
      style={{
        marginTop: "80px",
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 0px 7px 0px #00000014",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingInline: "25px",
        height: "54px",
      }}
    >
      <CustomTextField
        placeholder="Type your message..."
        fullWidth
        sx={{ maxWidth: 400 }}
        style={{ borderWidth: 0, fontFamily: "Montserrat" }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          // marginRight: "5%",
        }}
      >
        <Avatar
          src={"/assets/Header/micIcon.svg"}
          alt="robot"
          style={{
            height: 22,
            width: 22,
          }}
        />
        <Divider
          sx={{
            height: "54px",
            marginInline: "2vw",
          }}
          orientation="vertical"
          variant="fullWidth"
          flexItem
        />

        <Avatar
          style={{
            height: 22,
            width: 22,
          }}
          src={"/assets/Header/fileIcon.svg"}
          alt="robot"
        />
        <Divider
          sx={{
            height: "54px",
            marginInline: "2vw",
          }}
          orientation="vertical"
          variant="fullWidth"
          flexItem
        />
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "12px",
            lineHeight: "18px",
            textAlign: "left",
            textDecoration: "none",
            textAlign: "center",
            marginRight: "3%",
          }}
        >
          {"Send"}
        </Typography>
        <Avatar
          style={{
            height: 22,
            width: 22,
          }}
          src={"/assets/Header/sendIcon.svg"}
          alt="robot"
        />
      </Box>
    </div>
  );
};

export default InputHome;
