import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputAdornment,
  Box,
  Typography,
  Slider,
} from "@mui/material";

const CustomInput = ({
  label,
  placeholder,
  isIcon,
  icon,
  value,
  onChange,
  isSlider,
  sliderText = [],
  height = "38px",
}) => {
  return (
    <Box sx={{ marginTop: "10px", width: "100%" }}>
      <Typography
        sx={{
          fontSize: 14,
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#333333",
          lineHeight: "24px",
        }}
      >
        {label || ""}
      </Typography>
      {isSlider ? (
        <>
          <Slider
            defaultValue={value}
            aria-label="Default"
            valueLabelDisplay="auto"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {sliderText?.map((item) => (
              <Typography
                key={item}
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                {item || ""}
              </Typography>
            ))}
          </Box>
        </>
      ) : (
        <TextField
          // label={label}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: isIcon ? (
              <InputAdornment
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                position="start"
              >
                {icon}
              </InputAdornment>
            ) : null,
          }}
          sx={{
            "& .css-1umw9bq-MuiSvgIcon-root": {
              height: "15px",
              width: "15px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0F3764",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#0F3764",
                borderWidth: 1.5,
              },
            "& .css-2u11ia-MuiInputBase-input-MuiOutlinedInput-input": {
              height: height,
              padding: 0,
            },
          }}
        />
      )}
    </Box>
  );
};

CustomInput.defaultProps = {
  label: "",
  placeholder: "Enter text",
  isIcon: false,
  icon: null,
  value: "",
};

export default CustomInput;
