// components/HomeTab.js
import React, { useState } from "react";
import { Button } from "@mui/material";
import { Box, Typography, Avatar } from "@mui/material";
const HomeTab = () => {
  const [selected, setSelected] = useState(3);
  return (
    <div className="flex flex-col  space-y-4  headerContainer">
      <div className="flex flex-row gap-3">
        <img src="/assets/Header/robot.svg" alt="robot" />

        <h1 className="text-lg font-semibold text-gray-700 textHeader">
          How can I help with your budget today?
        </h1>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignSelf: "flex-start",
          marginLeft: "5vw",
          "@media (max-width:600px)": {
            flexDirection: "column",
            gap: 1,
            alignSelf: "center",
          },
        }}
      >
        {tabName.map((item) => (
          <Box
            key={item?.id}
            onClick={() => {
              setSelected(item?.id);
            }}
            sx={{
              cursor: "pointer",
              border: "1px solid #F0F0F0",
              borderColor: selected === item?.id ? "#FF9B19" : "#F0F0F0",
              display: "flex",
              flexDirection: "row",
              backgroundColor:
                selected === item?.id
                  ? "rgba(255, 155, 25, 0.1)"
                  : "transparent",
              borderRadius: 36,
              gap: 2,
              paddingRight: 1.7,
              paddingBlock: 2.1,
              paddingLeft: 2.3,
              alignItems: "center",
              "@media (max-width:600px)": {
                gap: 1,
                paddingRight: 1,
                paddingBlock: 1,
                paddingLeft: 1,
              },
            }}
          >
            <Avatar
              src={item?.icon}
              alt="robot"
              sx={{
                width: 20,
                height: 20,
              }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "14px",
                lineHeight: "18px",
                textAlign: "left",
                textDecoration: "none",
                textAlign: "center",
                color: selected === item?.id ? "#CD790C" : "#000",
              }}
            >
              {item?.name}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* </div> */}
    </div>
  );
};

export default HomeTab;

const tabName = [
  {
    id: 1,
    name: "Add Income",
    icon: "/assets/Header/plusIcon.svg",
  },
  {
    id: 2,
    name: "Set Goal",
    icon: "/assets/Header/riteIcon.svg",
  },
  {
    id: 3,
    name: "Show Savings insights",
    icon: "/assets/Header/optionIcon.svg",
  },
];
