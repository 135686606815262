import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (screen) => {
    // navigate("/subscription");
    navigate(screen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  useEffect(() => {
    if (location?.pathname === "/subscription") {
      setSelectedOption("subscription");
    } else if (location?.pathname === "/financialPlanner") {
      setSelectedOption("planner");
    } else {
      setSelectedOption();
    }
  }, [location]);
  const [selectedOption, setSelectedOption] = useState("chat"); 

  const handleNavigationDrawer = (path) => {
    navigate(path); // Navigate to the specified path
    handleDrawerToggle(); // Close the drawer
  };

  const drawer = (
  
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
    <img
      src="/assets/Header/richiLogo.svg"
      alt="Chat Icon"
      style={{ alignSelf: "center", marginLeft: 60, marginTop: 40 }}
    />
    <Divider />
    <List>
      {[
        // { label: "Chat With Richie", path: "/" },
        { label: "Subscription", path: "/subscription" },
        { label: "Financial Planner", path: "/financialPlanner" },
      ].map((item) => (
        <ListItem key={item.label} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => handleNavigationDrawer(item.path)}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                style: {
                  fontFamily: "Montserrat",
                  textAlign: "center",
                  fontSize: 14,
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0px -3px 10px 0px rgba(156, 156, 156, 0.2)",
          height: "65px",
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            display="flex"
            flexDirection="row"
            gap={5}
            alignItems="center"
            paddingInline="5.3vw"
            justifyContent={"space-between"}
            width={"100%"}
          >
            <div
            style={{
              cursor:'pointer'
            }}
            onClick={()=>navigate('/home')}
            >
            <img src="/assets/Header/richiLogo.svg" alt="" component="div" />

            </div>

            <Box
              display="flex"
              flexDirection="row"
              gap={"1vw"}
              alignItems="center"
              sx={{
                "@media (max-width:600px)": {
                  display: "none",
                },
              }}
            >
              {/* Chat with Richie Button */}
              <Button
                variant="contained"
                onClick={() => setSelectedOption("chat")}
                sx={{
                  backgroundColor: "#48BF84",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  lineHeight: "15px",
                  fontSize: {
                    xs: "0.5rem",
                    sm: "0.8rem",
                    md: "0.8rem",
                    lg: "0.8rem",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  "&:hover": {
                    backgroundColor: "#3AA974",
                  },
                  // borderBottom:
                  //     selectedOption === "chat"
                  //         ? "2px solid black"
                  //         : "none",
                }}
              >
                Chat with Richie
                <img
                  src="/assets/Header/message-edit-02.svg"
                  alt="Chat Icon"
                  style={{ width: "18px", height: "18px" }}
                />
                {/* {selectedOption === "chat" && (
                  <div
                    style={{
                      height: 4,
                      background: "black",
                      width: 130,
                      position: "absolute",
                      top: "55px",
                    }}
                  />
                )} */}
              </Button>
              <Box
                sx={{
                  // backgroundColor: "red",
                  width: "1vw",
                }}
              />
              {/* Subscription Option */}
              <Typography
                variant="body1"
                onClick={() => {
                  setSelectedOption("subscription");
                  handleNavigate("/subscription");
                }}
                sx={{
                  color: selectedOption === "subscription" ? "#000" : "#757575",
                  fontWeight: "bold",
                  mr: 2,
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  position: "relative",
                  fontSize: {
                    xs: "0.5rem",
                    sm: "0.8rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                  // fontSize: "1.3vw",

                  // borderBottom:
                  //     selectedOption === "subscription"
                  //         ? "2px solid black"
                  //         : "none",
                }}
              >
                Subscription
                {selectedOption === "subscription" && (
                  <div
                    style={{
                      height: 4,
                      background: "black",
                      width: "100%",
                      position: "absolute",
                      top: "43px",
                    }}
                  />
                )}
              </Typography>

              {/* Financial Planner Option */}
              <Typography
                variant="body1"
                onClick={() => {
                  setSelectedOption("planner");
                  handleNavigate("/financialPlanner");
                }}
                sx={{
                  color: selectedOption === "planner" ? "#000" : "#757575",
                  fontWeight: "bold",
                  mr: 2,
                  display: { xs: "none", sm: "block" },
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  position: "relative",
                  // fontSize: "1.3vw",
                  fontSize: {
                    xs: "0.5rem",
                    sm: "0.8rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                  // borderBottom:
                  //     selectedOption === "planner"
                  //         ? "2px solid black"
                  //         : "none",
                }}
              >
                Financial Planner
                {selectedOption === "planner" && (
                  <div
                    style={{
                      height: 4,
                      background: "black",
                      width: 130,
                      position: "absolute",
                      width: "100%",
                      position: "absolute",
                      top: "43px",
                    }}
                  />
                )}
              </Typography>
              <Box
                sx={{
                  width: "1vw",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1px",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="User Profile"
                  src="https://via.placeholder.com/150"
                  sx={{ width: 40, height: 40, mr: 1 }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                      // fontSize: "1.3vw",
                      fontSize: {
                        xs: "0.5rem",
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1rem",
                      },
                    }}
                  >
                    Vipul Sharma
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#757575",
                      fontFamily: "Montserrat",
                      fontSize: "1.1vw",
                    }}
                  >
                    View Profile
                  </Typography>
                </Box>
                <IconButton>
                  <MoreVertIcon
                    sx={{
                      marginRight: -3,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
