// src/NavigationContainer.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Login from "../Screen/Login";
import OtpScreen from "../Screen/Otp";
import OnBoarding from "../Screen/Onboarding";
import Home from "../Screen/Home/ Home";
import LandingScreen from "../Screen/Landing";
import Subscription from "../Screen/Subscription";
import FinancialPlanner from "../Screen/FinancialPlanner";

function NavigationContainer() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<OnBoarding />} />
          <Route path="/financialPlanner" element={<FinancialPlanner />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<OtpScreen />} />
          <Route path="/landing" element={<LandingScreen />} />
          <Route path="/subscription" element={<Subscription />} />
        </Routes>
      </div>
    </Router>
  );
}

export default NavigationContainer;
