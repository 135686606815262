import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import HomeTab from "./HomeTab";
import ChartSection from "./Chart";
import InputHome from "./InputHome";
import DrawerAppBar from "../../Components/DrawerAppBar";

const Home = () => {
  const Footer = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1%",
        }}
      >
        {[
          {
            name: "Savings Rate",
            percent: "24%",
            icon: "/assets/Header/savingRate.svg",
          },
          {
            name: "Debt Ratio",
            percent: "32%",
            icon: "/assets/Header/DebtRatio.svg",
          },
        ].map((item) => (
          <Box
            sx={{
              padding: "16px",
              borderColor: "#F0F0F0",
              borderWidth: 1,
              backgroundColor: "#fff",
              width: 151,
              borderRadius: "8px",
              marginTop: "13px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              gap: "3%",
            }}
          >
            <Avatar src={item?.icon} alt="robot" style={{ padding: 2 }} />
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                {item?.name}
              </Typography>

              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                {item?.percent}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };
  return (
    <>
      <DrawerAppBar />
      <div
        style={{
          marginInline: "7vw",
          marginTop: "39px",
        }}
      >
        <HomeTab />
        <Box
          sx={{
            marginLeft: "5vw",
            "@media (max-width:600px)": {
              marginLeft: "0vh",
            },
          }}
        >
          <ChartSection />
          <Footer />
          <InputHome />
        </Box>
        <Box sx={{ height: 100 }} />
      </div>
    </>
  );
};

export default Home;
