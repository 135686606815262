import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OnBoarding = () => {
    const navigate = useNavigate(); 

    const handleNavigateToLogin = () => {
        navigate("/login");
    };
useEffect(()=>{

    setTimeout(()=>{
        handleNavigateToLogin()
    }, 2000)
},[])

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
                margin: 0,
                padding: 0,
            }}
        >
            <img
                src="/assets/Header/Onboard.png"
                alt="Onboarding"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", 
                }}
            />
        </div>
    );
};

export default OnBoarding;
