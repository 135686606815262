import NavigationContainer from "./NavigationContainer/NavigationContainer";
import Subscription from "./Screen/Subscription";

function App() {
  return (
    <div className="App">
      <NavigationContainer />
    </div>
  );
}

export default App;
