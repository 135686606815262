import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Avatar,
} from "@mui/material";

const FinancialPlanModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Avatar
          src={"/assets/Header/crossIcon.svg"}
          alt="gggrrow"
          sx={{
            position: "absolute",
            top: 18,
            alignSelf: "center",
          }}
          onClick={onClose}
        />
        <Box
          sx={{
            width: "25%",
            margin: "auto",
            mt: 10,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 0,
            maxHeight: "80vh",
            overflow: "auto",
            "@media (max-width:600px)": {
              width: "80%",
            },
          }}
        >
          <img
            src={"/assets/Header/modalImge.svg"}
            alt="gggrrow"
            style={{
              height: "100%",
              width: "100%",
            }}
            onClick={onClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default FinancialPlanModal;
