import React from "react";
import { Box, Typography, Button, Grid, useMediaQuery } from "@mui/material";
import DrawerAppBar from "../Components/DrawerAppBar";

const Subscription = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url('/assets/Header/backgraund.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <DrawerAppBar />
      {/* Title Section */}
      {/* Title Section */}
      <Box
        sx={{
          textAlign: "left",
          mt: isMobile ? 15 : 15,
          alignSelf: "flex-start",
          marginLeft: !isMobile ? 34 : 4,
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h4"}
          fontWeight="bold"
          sx={{
            fontSize: isMobile ? 16 : 18, // Adjust font size for responsiveness
          }}
        >
          Premium Financial Planner
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: isMobile ? 14 : 16, // Adjust font size for responsiveness
            mt: 0.5, // Slight spacing between lines
          }}
        >
          Your comprehensive financial roadmap for success
        </Typography>
      </Box>

      {/* Cards Section */}
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 0, width: "100%", px: isMobile ? 2 : 8 }}
      >
        {/* First Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "white",
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box sx={{ padding: 3 }}>
              {/* Price and Plan Information */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    fontSize: isMobile ? 30 : 40,
                    color: "rgba(15, 55, 100, 1)",
                  }}
                >
                  ₹0
                  <Typography
                    component="span"
                    sx={{
                      fontSize: isMobile ? 16 : 20,
                      fontWeight: "bold",
                      marginLeft: 0.5,
                    }}
                  >
                    /month
                  </Typography>
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(255, 155, 25, 1)",
                    padding: "4px 10px",
                    borderRadius: 36,
                    color: "rgba(255, 155, 25, 1)",
                    backgroundColor: "rgba(255, 155, 25, 0.1)",
                  }}
                >
                  Free 1 Month
                </Typography>
              </Box>

              {/* Features List */}
              <Box sx={{ mt: 2 }}>
                {[
                  "Voice messages anywhere",
                  "Voice messages anywhere",
                  "Voice messages anywhere",
                ].map((text, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <img
                      src="/assets/Header/Vector.svg"
                      alt="Icon"
                      style={{ width: 16, height: 16 }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isMobile ? 14 : 16,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(205, 205, 205, 1)",
                color: "black",
                textTransform: "none",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              Already Using
            </Button>
          </Box>
        </Grid>

        {/* Second Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              textAlign: "center",
              borderRadius: 2,
              backgroundColor: "white",
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box sx={{ padding: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    fontSize: isMobile ? 30 : 40,
                    color: "rgba(15, 55, 100, 1)",
                  }}
                >
                  ₹300
                  <Typography
                    component="span"
                    sx={{
                      fontSize: isMobile ? 16 : 20,
                      fontWeight: "bold",
                      marginLeft: 0.5,
                    }}
                  >
                    /3yrs
                  </Typography>
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid rgba(255, 155, 25, 1)",
                    padding: "4px 10px",
                    borderRadius: 36,
                    color: "rgba(255, 155, 25, 1)",
                    backgroundColor: "rgba(255, 155, 25, 0.1)",
                  }}
                >
                  Paid for 3 Years
                </Typography>
              </Box>

              {/* Features List */}
              <Box sx={{ mt: 2 }}>
                {[
                  "Voice messages anywhere",
                  "Voice messages anywhere",
                  "Voice messages anywhere",
                ].map((text, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <img
                      src="/assets/Header/Vector.svg"
                      alt="Icon"
                      style={{ width: 16, height: 16 }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isMobile ? 14 : 16,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(15, 55, 100, 1)",
                color: "white",
                textTransform: "none",
                width: "100%",
                borderRadius: "0 0 8px 8px",
              }}
            >
              Buy Now
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Robot Image */}
      <Box
        component="img"
        src="/assets/Header/robert.svg"
        alt="Robot with Piggy Bank"
        sx={{
          width: isMobile ? 150 : 200,
          height: "auto",
          mt: 4,
        }}
      />
    </Box>
  );
};

export default Subscription;
