import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const labels = [" ", "Debt-to-Income Ratio", "Discretionary Spending Rate", ""];
const chartData = [
  {
    label: "This Week",
    data: [0, 7, 3, 10],
    borderColor: "#48BF84",
    backgroundColor: "#48BF84",
  },
  {
    label: "Last Week",
    borderColor: "#2D96BE",
    backgroundColor: "#2D96BE",
  },
  {
    label: "This week",
    borderColor: "#FF9F1C",
    backgroundColor: "#FF9F1C",
  },
  {
    label: "Last week",
    borderColor: "#E71D36",
    backgroundColor: "#E71D36",
  },
];

const generateDatasets = (data) =>
  data.map((item) => ({
    label: item.label,
    data: item.data,
    borderColor: item.borderColor,
    backgroundColor: item.backgroundColor,
    fill: true,
    tension: 0.4,
    borderWidth: 2,
  }));

const WeeklyComparisonChart = () => {
  const data = {
    labels,
    datasets: generateDatasets(chartData),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "",
          font: {
            size: 12,
          },
        },
      },
      y: {
        min: 0,
        max: 10,
        ticks: {
          stepSize: 2,
        },
        title: {
          display: true,
          text: "Score",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "400px",
        padding: "16px",
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        marginTop: "2%",
      }}
    >
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          marginLeft: "3%",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Weekly Comparison</Typography>
          <Avatar
            src={"/assets/Header/arrrowDown.svg"}
            alt="arrow"
            sx={{
              height: 24,
              width: 24,
              marginLeft: "1%",
            }}
          />
        </Box>
        {Data.map((item) => (
          <Box>
            <Typography variant="h6"></Typography>
          </Box>
        ))}
      </Box>
      <Line
        data={data}
        options={options}
        style={{
          paddingBottom: "4%",
        }}
      />
    </Box>
  );
};

export default WeeklyComparisonChart;

const Data = [
  {
    label: "This Week",
    data: [0, 7, 3, 10],
    borderColor: "#48BF84",
    backgroundColor: "#48BF84",
  },
  {
    label: "Last Week",
    borderColor: "#2D96BE",
    backgroundColor: "#2D96BE",
  },
  {
    label: "This week",
    borderColor: "#FF9F1C",
    backgroundColor: "#FF9F1C",
  },
  {
    label: "Last week",
    borderColor: "#E71D36",
    backgroundColor: "#E71D36",
  },
];
