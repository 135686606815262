


import React from "react";
import { Box, Typography, TextField, Button, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OtpScreen = () => {
    const navigate = useNavigate();
    
  const handleNavigate = () => {
    navigate("/landing");
  };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url('/assets/Header/backgraund.png')`, // Replace with your image path
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                padding: 2,
            }}
        >
            <Box>
                <Box
                    component="img"
                    src="/assets/Header/robert.svg"
                    alt="Richie Robot"
                    sx={{
                        width: 150,
                        height: "auto",
                        mx: "auto",
                        mt: -8,
                        position: "relative",
                    }}
                />
                <Card
                    sx={{
                        maxWidth: 400,
                        width: "100%",
                        textAlign: "center",
                        borderRadius: 3,
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                        padding: 3,
                        backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly transparent white background for the card
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", color: "#0b3d91", mb: 2, fontSize: 20 }}
                        >
                            Login To Richie
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "black",
                                fontSize: 20,
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                            }}
                        >
                            Enter Verification Code
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#6c757d",
                                mb: 3,
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                color:'black'
                            }}
                        >
                            We will send you a confirmation code on this number *****9896589
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1, 
                                mb: 3,
                            }}
                        >
                            {Array.from({ length: 6 }).map((_, index) => (
                                <TextField
                                    key={index}
                                    // placeholder="•"
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 1, 
                                        style: {
                                            textAlign: "center",
                                            fontSize: 24,
                                            fontWeight: "bold",
                                            height:10
                                        },
                                    }}
                                    sx={{
                                        width: 50, // Fixed width for OTP fields
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: 2,
                                        },
                                    }}
                                />
                            ))}
                        </Box>

                        <Button
                            variant="contained"
                            sx={{
                                width: "100%",
                                backgroundColor: "rgba(15, 55, 100, 1)",
                                color: "#fff",
                                padding: "10px 0",
                                borderRadius: "8px",
                                fontWeight: "bold",
                                "&:hover": {
                                    backgroundColor: "#084298",
                                },
                            }}
                            onClick={handleNavigate}
                        >
                            Submit
                        </Button>
                        <Typography
                            variant="caption"
                            sx={{
                                display: "block",
                                mt: 3,
                                color: "black",
                                fontFamily: "Montserrat",
                                fontSize:16
                            }}
                        >
                           Don’t Receive the OTP?<strong> Resend OTP</strong> 
                         
                          
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default OtpScreen;
