// import React from "react";
// import { Box, Card, Typography, Button } from "@mui/material";

// const LandingScreen = () => {
//     return (
//         <Box
//             sx={{
//                 minHeight: "100vh",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 backgroundImage: `url('/assets/Header/backgraund.png')`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 backgroundRepeat: "no-repeat",
//                 padding: 2,
//                 position: "relative", // Allows positioning for inner elements
//             }}
//         >
//             {/* Richie Logo Outside the Card */}
//             <Box
//                 component="img"
//                 src="/assets/Header/richiLogo.svg"
//                 alt="Richie Logo"
//                 sx={{
//                     width: 100,
//                     height: "auto",
//                     position: "absolute",
//                     top: "10%",
//                     left: "50%",
//                     transform: "translateX(-50%)",
//                 }}
//             />

//             {/* Container for Robot and Speech Bubble */}
//             <Box
//                 sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                  alignItems: "center",
//                     // justifyContent: "center",
//                     gap: -1,
//                 }}
//             >
//                 {/* Robot with Piggy Bank */}
//                 <Box
//                     component="img"
//                     src="/assets/Header/robert.svg"
//                     alt="Robot with Piggy Bank"
//                     sx={{
//                         width: 250,
//                         height: 250,
//                     }}
//                 />

//                 {/* Speech Bubble */}
//                 <img src="/assets/Header/leftPoly.svg"
//                 style={{
//                     position:'absolute',
//                     left:649,
//                     top:290,
//                     alignSelf:'center'

//                 }}
//                 />
//                 <Box
//                     sx={{
//                         padding: 2,
//                         borderRadius: "8px",
//                         maxWidth: 400,
//                         borderWidth: 1,
//                         borderColor: 'black',
//                         height: 150,
//                         mt: 4,
//                         display: "flex",
//                         flexDirection: "column", 
//                         justifyContent: "space-between", 
//                         textAlign: "start",
//                         ml:-5,
//                         height:170
//                     }}
//                 >
//                     <Typography variant="h6" gutterBottom
//                     sx={{
//                         fontFamily: "Montserrat",
//                     }}
//                     >
//                         Hey...
//                     </Typography>
//                     <Typography variant="body1" gutterBottom
//                        sx={{
//                         fontFamily: "Montserrat",
//                         fontStyle: "italic",
//                         color:'rgba(15, 55, 100, 1)',
//                         fontSize:20

                        
//                     }}
//                     >
//                         <strong>Welcome to Richie</strong>
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary" gutterBottom
//                         sx={{
//                             fontFamily: "Montserrat",
//                             // color:'rgba(15, 55, 100, 1)',
//                             fontSize:12,

    
                            
//                         }}
//                     >
//                         I am your assistant and I am helping you in each and every step.
//                         So are you ready???
//                     </Typography>
//                     <Button
//                         variant="contained"
//                         sx={{
//                             backgroundColor: "rgba(15, 55, 100, 1)",
//                             width: "60%",
//                             alignSelf: "flex-start", // Align button to the start of the box
//                             mt:2.5
//                         }}
//                     >
//                         Let's Go
//                     </Button>
//                 </Box>

//             </Box>
//         </Box>
//     );
// };

// export default LandingScreen;


import React from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LandingScreen = () => {
    const isMobile = useMediaQuery("(max-width:768px)"); // Adjust based on screen width
    const navigate = useNavigate();
    
    const handleNavigate = () => {
      navigate("/home");
    };
    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url('/assets/Header/backgraund.png')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                padding: 2,
                position: "relative",
            }}
        >
            {/* Richie Logo Outside the Card */}
            <Box
                component="img"
                src="/assets/Header/richiLogo.svg"
                alt="Richie Logo"
                sx={{
                    width: isMobile ? 80 : 100,
                    height: "auto",
                    position: "absolute",
                    top: isMobile ? "5%" : "10%",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}
            />

            {/* Container for Robot and Speech Bubble */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    gap: isMobile ? 2 : -1,
                }}
            >
                {/* Robot with Piggy Bank */}
                <Box
                    component="img"
                    src="/assets/Header/robert.svg"
                    alt="Robot with Piggy Bank"
                    sx={{
                        width: isMobile ? 150 : 300,
                        height: "auto",
                        mb: isMobile ? 2 : 0,
                    }}
                />

                {/* Speech Bubble */}
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: 2,
                        borderRadius: "8px",
                        maxWidth: 400,
                        borderWidth: 1,
                        borderColor: "black",
                        textAlign: "start",
                        mb: isMobile ? 0 : 4,
                        height:140
                    }}
                >
                { !isMobile &&   <img
                        src="/assets/Header/leftPoly.svg"
                        alt="Speech Bubble Tail"
                        style={{
                            position: "absolute",
                            left: isMobile ? "-10px" : "-18px",
                            top: "20px",
                            // width: isMobile ? "30px" : "50px",
                        }}
                    />}
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            fontFamily: "Montserrat",
                        }}
                    >
                        Hey...
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "italic",
                            color: "rgba(15, 55, 100, 1)",
                            fontSize: isMobile ? 16 : 20,
                        }}
                    >
                        <strong>Welcome to Richie</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                        sx={{
                            fontFamily: "Montserrat",
                            fontSize: isMobile ? 10 : 12,
                        }}
                    >
                        I am your assistant and I am helping you in each and every step.
                        So are you ready???
                    </Typography>
                                     <Button
                      variant="contained"
                      sx={{
                          backgroundColor: "rgba(15, 55, 100, 1)",
                          width: "60%",
                          alignSelf: "flex-start", // Align button to the start of the box
                        //  top:30
                      }}
                      onClick={handleNavigate}
                  >
                      Let's Go
                  </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default LandingScreen;
