import React from "react";
import { Select, MenuItem, Typography, Box } from "@mui/material";

const CustomSelect = ({ label, placeholder, value, onChange }) => {
  return (
    <Box sx={{ marginTop: "10px", width: "100%" }}>
      <Typography
        sx={{
          fontSize: 14,
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#333333",
          lineHeight: "24px",
        }}
      >
        {label || ""}
      </Typography>

      <Select
        sx={{
          width: "100%",
          height: "38px",
          padding: 0,
          "& fieldset": {
            borderRadius: "8px",
          },
          "&:hover fieldset": {
            borderColor: "#0F3764",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#0F3764",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0F3764",
            borderWidth: 1.5,
          },
          "& .MuiSelect-root.Mui-open .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF5733",
          },
        }}
        value={value || "Single"}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty // To show placeholder with styling
      >
        <MenuItem value={"Single"}>Single</MenuItem>
        <MenuItem value={"Married"}>Married</MenuItem>
      </Select>
    </Box>
  );
};

export default CustomSelect;
