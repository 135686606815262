import {
  Box,
  Button,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import DrawerAppBar from "../Components/DrawerAppBar";
import CustomInput from "../Components/CustomeInputField";
import { CurrencyRupee } from "@mui/icons-material";
import CustomSelect from "../Components/CustomSelect";
import FinancialPlanModal from "./FinancialPlanModal";
import { useState } from "react";
const FinancialPlanner = () => {
  const [open, setOpen] = useState(false);
  const PersonalInfo = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Personal Information</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            width: "100%",
          }}
        >
          <CustomInput
            label="Full Name"
            placeholder="Enter"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
          />
          <CustomInput
            label="Age"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
          />
        </Box>
        <CustomInput
          label="Location"
          isIcon={true}
          // value={""}
          onChange={(value) => {}}
        />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <CustomInput
            label="Profession"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
          />
          {/* <CustomInput
            label="Marital Status"
            isIcon={true}
            value={""}
            onChange={(value) => {}}
            isDropDown={true}
          /> */}
          <CustomSelect
            label="Marital Status"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
          />
        </Box>
      </Box>
    );
  };

  const IncomeDetails = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Income Details</Typography>
        <CustomInput
          label="Gross Annual Income"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Net Monthly Income"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Additional Income Savngs"
          isIcon={true}
          // value={""}
          onChange={(value) => {}}
        />
      </Box>
    );
  };

  const Expenses = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Expenses Breakdown</Typography>
        <CustomInput
          label="Fixed Monthly Expense"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Variable Expense"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Occasional Cost"
          isIcon={true}
          // value={""}
          onChange={(value) => {}}
        />
      </Box>
    );
  };

  const DebtInformation = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Debt Information</Typography>
        <CustomInput
          label="Outstanding Loans"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Total EMI Amount"
          isIcon={true}
          icon={<CurrencyRupee />}
          // value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Credit Card Usage(%)"
          valueLabelDisplay="auto"
          isSlider={true}
          onChange={(value) => {}}
          value={50}
          sliderText={["0%", "100%"]}
        />
      </Box>
    );
  };

  const SavingsAndInvestments = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Savings And Investments</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            width: "100%",
          }}
        >
          <CustomInput
            label="Mutual Fund"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
            icon={<CurrencyRupee />}
          />
          <CustomInput
            label="Stock"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
            icon={<CurrencyRupee />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            width: "100%",
          }}
        >
          <CustomInput
            label="Real Estate"
            isIcon={true}
            // value={""}
            
            onChange={(value) => {}}
            icon={<CurrencyRupee />}
          />
          <CustomInput
            label="Gold"
            isIcon={true}
            // value={""}
            onChange={(value) => {}}
            icon={<CurrencyRupee />}
          />
        </Box>
      </Box>
    );
  };

  const CreditScoreDetails = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Credit Score Details</Typography>

        <CustomInput
          label="Latest Credit Score"
          isIcon={true}
          value={""}
          onChange={(value) => {}}
        />
        <CustomInput
          label="Outstanding Issues"
          isIcon={true}
          value={""}
          onChange={(value) => {}}
        />

        <CustomInput
          label="Credit Utilization(%)"
          valueLabelDisplay="auto"
          isSlider={true}
          onChange={(value) => {}}
          value={50}
          sliderText={["0%", "100%"]}
        />
      </Box>
    );
  };

  const RiskProfile = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Risk Profile</Typography>
        <CustomInput
          label="Investment Risk Tolerance"
          valueLabelDisplay="auto"
          isSlider={true}
          onChange={(value) => {}}
          value={50}
          sliderText={["Low Risk", "Medium Risk", "High Risk"]}
        />
      </Box>
    );
  };

  const FinancialGoals = () => {
    return (
      <Box sx={styles?.inputContaner}>
        <Typography sx={styles.textHeader}>Financial Goals</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            "@media (max-width:600px)": {
              flexDirection: "column",
            },
          }}
        >
          <CustomInput
            label="Short-Term Goals (1 year)"
            isIcon={true}
            value={""}
            onChange={(value) => {}}
            height="45px"
          />
          <CustomInput
            label="Long-Term Goals (5+ years)"
            isIcon={true}
            value={""}
            onChange={(value) => {}}
            height="45px"
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", marginInline: "7vw", flexDirection: "column" }}>
      <DrawerAppBar />

      <Box
        sx={{
          marginTop: "150px",
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-start",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Premium Financial Planner
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#33333380",
          }}
        >
          Your comprehensive financial roadmap for success
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "44px",
          display: "flex",
          gap: "1vw",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <PersonalInfo />
        <IncomeDetails />
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          gap: "1vw",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Expenses />
        <DebtInformation />
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          gap: "1vw",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <SavingsAndInvestments />
        <CreditScoreDetails />
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          gap: "1vw",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <RiskProfile />
        <Box
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          gap: "1vw",
        }}
      >
        <FinancialGoals />
      </Box>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        sx={styles.button}
      >
        Submit
      </Button>
      <Box sx={{ height: "5vh" }} />
      <FinancialPlanModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default FinancialPlanner;

const styles = {
  inputContaner: {
    backgroundColor: "#FFFFFF",
    borderWidth: "1px",
    borderColor: "#F0F0F0",
    borderRadius: "8px",
    display: "flex",
    paddingBlock: "20px",
    paddingInline: "14px",
    flexDirection: "column",
    width: "100%",
  },
  textHeader: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "10px",
    marginBottom: "10px",
  },
  button: {
    width: "30vw",
    marginTop: "10vh",
    alignSelf: "center",
    background: " linear-gradient(306.31deg, #0F3764 18.77%, #305B8B 61.19%)",

    color: "#fff",
    height: "45px",
    borderRadius: "8px",
    fontWeight: "bold",
    boxShadow: "0px 4px 4px 0px #00000040;",
    "&:hover": {
      backgroundColor: "#084298",
    },
  },
};
