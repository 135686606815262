
import React from "react";
import { Box, Typography, TextField, Button, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/otp");
  };


  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url('/assets/Header/backgraund.png')`, // Replace with your image path
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: 2,
      }}
    >
      <Box>
        <Box
          component="img"
          src="/assets/Header/robert.svg"
          alt="Richie Robot"
          sx={{
            width: 150,
            height: "auto",
            mx: "auto",
            mt: -8,
            position: "relative",
          }}
        />
        <Card
          sx={{
            maxWidth: 400,
            width: "100%",
            textAlign: "center",
            borderRadius: 3,
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            padding: 3,
            backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly transparent white background for the card
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#0b3d91", mb: 2, fontSize: 20 }}
            >
              Login To Richie
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                fontSize: 20,
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
            >
              Enter Your Mobile Number
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#6c757d",
                mb: 3,
                fontSize: 14,
                fontFamily: "Montserrat",
              }}
            >
              We will send you a confirmation code
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                border: "1px solid #ced4da",
                borderRadius: "8px",
                padding: "5px 10px",
                mb: 3,
              }}
            >
              <Box
                component="img"
                src="/assets/Header/flag.svg"
                alt="India Flag"
                sx={{ width: 74, height: 24 }}
              />
              <Typography sx={{ color: "#6c757d", fontSize: 16 }}>+91</Typography>
              <TextField
                placeholder="Enter Mobile Number"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fullWidth
              />
            </Box>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "rgba(15, 55, 100, 1)",
                color: "#fff",
                padding: "10px 0",
                borderRadius: "8px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#084298",
                },
              }}
              onClick={handleNavigate}
            >
              Verify
            </Button>
            <Typography
              variant="caption"
              sx={{
                display: "block",
                mt: 3,
                color: "#6c757d",
                fontFamily: "Montserrat",
              }}
            >
              By Continuing you Agree to Richie
              <br />
              <Typography
                component="span"
                variant="caption"
                sx={{
                  color: "black",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Terms Of Use
              </Typography>{" "}
              &{" "}
              <Typography
                component="span"
                variant="caption"
                sx={{ color: "black", fontSize: 16, fontWeight: "bold" }}
              >
                Privacy Policy
              </Typography>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Login;
